/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import 'unfetch/polyfill'; // Polyfill for `fetch` in IE11.
import { ApolloProvider } from 'react-apollo';
import { client } from './src/apollo/client';
import SessionCheck from './src/components/session-check/session-check';
import { SplunkRum } from '@splunk/otel-web';

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return (
    <SessionCheck>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </SessionCheck>
  );
};

export const onClientEntry = () => {
  SplunkRum.init({
    beaconUrl: 'https://rum-ingest.us1.signalfx.com/v1/rum',
    rumAuth: process.env.GATSBY_SPLUNK_RUM_AUTH,
    app: 'M2-Website', // Replace with your app name
    environment: process.env.GATSBY_ENV || 'DEVELOP' // Track the environment
    // Optional configurations
    // globalAttributes: { key: 'value' }, // Custom attributes
    // allowInsecureBeacon: false, // Disable for production deployments
  });
};
